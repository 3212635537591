
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {YargiBirimleriCevap} from "@/plugins/uyap-plugin/uyap/YargiBirimleri";

@Component({
  components: {}
})

export default class YargiBirimiPicker extends Vue {
  @Prop({required: true}) yargiTuru!: number;

  items: YargiBirimleriCevap = [{altSistKodu: "-1", tablo: "1101", kod: "İCRA DAİRESİ"}];

  @Watch('yargiTuru')
  onYargiTuruChange() {
    this.load();
  }

  mounted() {
    this.load();
  }

  async load() {
    if (this.yargiTuru) {
      let yargiBirimleri = await this.$uyap.YargiBirimleri().run({yargiTuru: this.yargiTuru});
      this.items = yargiBirimleri;
    }
  }
}
