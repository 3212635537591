
import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {MahkemeBirimleriRow} from "@/plugins/uyap-plugin/uyap/CommonInterfaces";

@Component({
  components: {}
})
export default class UyapMahkemePicker extends Mixins(SimpleInputMixin) {
  @Prop({required: true}) yargiBirimi !: string;
  @Prop({required: true}) yargiTuru !: number;
  @Prop({required: true}) dosyaKapaliMi !: boolean;
  @Prop() showTumu!: boolean | string;
  @Prop() defaultFirst!: boolean | string;

  items: Array<MahkemeBirimleriRow | { birimAdi: string, birimId: string }> = [];
  loading = false;

  get isShowTumu() {
    return this.showTumu === "" || this.showTumu;
  }

  get isDefaultFirst() {
    return this.defaultFirst === "" || this.defaultFirst;
  }

  mounted() {
    this.onArgChange();
  }

  @Watch('yargiBirimi')
  @Watch('yargiTuru')
  @Watch('dosyaKapaliMi')
  onArgChange() {
    if (this.yargiBirimi && this.yargiTuru && this.dosyaKapaliMi !== undefined)
      this.load();
  }

  async load() {
    this.loading = true;
    let items = [];
    if (this.isShowTumu)
      items.push({birimAdi: 'Tümü', birimId: ''});
    let mahkemeler = await this.$uyap.MahkemeBirimleri().run({
      yargiBirimi: this.yargiBirimi,
      yargiTuru: this.yargiTuru,
      dosyaKapaliMi: this.dosyaKapaliMi
    });
    this.items = [...items, ...mahkemeler.items];
    if (this.isDefaultFirst)
      this.localValue = items[0].birimId;
    this.loading = false;
  }
}
